.details-text {
  p {
    margin-bottom: 1.5rem;
  }

  li {
    margin-left: 10px;
    padding-left: 5px;
  }
}
